import * as React from 'react';
import styled from 'styled-components';

import Text from './Text';

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

const CardImageWrapper = styled.div`
  background-color: black;
  width: auto;
  height: 200px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const CardTitle = styled(Text)`
  font-size: 1.2rem;
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 10px;
`;

const Card = ({ title, snippet }) => (
  <Container>
    <CardImageWrapper />
    
    <CardContent>
      <CardTitle>{title}</CardTitle>
      <Text>{snippet}</Text>
    </CardContent>
  </Container>
);

export default Card;
