import * as React from 'react';
import styled from 'styled-components';

const GridList = styled.div`
  display: grid;
  margin: auto;
  padding: 30px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(1, minmax(300px, 1fr));

  @media(min-width: 800px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
`;

export default GridList;
