import React from "react"
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from "../components/lib/Layout"
import Title from "../components/lib/Title"
import GridList from '../components/lib/GridList';
import Card from '../components/lib/Card';

import '../components/layout.css';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Banner = styled(Title)`
  font-size: 1.2rem;
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 10px;
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          posts { id, title, snippet }
        }
      }
    }
  `)

  const { title, posts } = data.site.siteMetadata;

  const gridItems = posts.map((post) => <Card key={post.id} title={post.title} snippet={post.snippet} />);

  return (
    <Layout title={title}>
      <Container>
        <Banner>Coming Soon...</Banner>
      </Container>
    </Layout>
  );
}

export default IndexPage
